/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.



(function($) {
  var siteName = '';
  var sliderRes = $.ajax(siteName + '/wp-json/wp/v2/slider/', {method: 'GET'});

  var initAutoScroll = function (obj, width, len, speed) {
    var pos    = 0;
    var scroll = 0;
    var count  = 0;
    var prev   = 0;
    var tWidth = 0;

    window.setInterval(function() {
      count++;
      pos    = obj.scrollLeft();
      scroll = pos + width;
      prev   = scroll - width;
      tWidth = obj.find('.slider__container').find('.slider__item:last-child').offset().left - width;

      obj.animate({scrollLeft: scroll}, 400);

      if (tWidth < obj.width()) {
        setTimeout(function () {
          obj.animate({scrollLeft: 0}, 400);
        }, 2000);
      }
    }, speed);
  };

  var initNetworkSlider = function (obj,res) {
    var arr       = res;
    var len       = arr.length;
    var slider    = obj;
    var size      = 0;
    var margin    = 5;
    var scroll    = 0;
    var containerWidth = 0;
    var container = slider.find('.slider__container');

    container.append('<div class="slider__item"><i class="fa fa-spinner fa-pulse fa-3x fa-fw"></i><span class="sr-only">Loading...</span></div>');

    size   = parseInt(container.find('.slider__item').css('width'));
    containerWidth = len * (size + margin);

    container.css('width', containerWidth);
    container.html('');

    for (var i = 0; i < len; i++) {
      container.append('<div class="slider__item"><a href="' + arr[i].url + '" target="new"><img src="' + arr[i].network + '" alt="network logo" class="img-responsive"></a></div>');
    }
    initAutoScroll(slider, (size + margin), len, 3000);
  };

  var initBookSlider = function (obj,res) {
    var arr       = res;
    var len       = arr.length;
    var slider    = obj;
    var size      = 0;
    var margin    = 5;
    var scroll    = 0;
    var containerWidth = 0;
    var container = slider.find('.slider__container');

    container.append('<div class="slider__item"><i class="fa fa-spinner fa-pulse fa-3x fa-fw"></i><span class="sr-only">Loading...</span></div>');

    size   = parseInt(container.find('.slider__item').css('width'));
    containerWidth = len * (size + margin);

    container.css('width', containerWidth);
    container.html('');

    for (var i = 0; i < len; i++) {
      container.append('<div class="slider__item"><a href="' + arr[i].url + '" target="new"><img src="' + arr[i].image + '" alt="network logo" class="img-responsive"></a></div>');
    }
    // initAutoScroll(slider, (size + margin), len, 3000);
  };

  // var initPopularShows = function (obj) {
  //   //http://demo.wp-api.org/wp-json/wp/v2/posts
  //   var showsArr = obj;
  //   var showID, showTitle, showLink;

  //   for (var i = 0; i < showsArr.length; i++) {

  //     showID = showsArr[i].show.ID;
  //     showData = $.get(siteName + '/wp-json/wp/v2/posts/' + showID);

  //     showData.then(function (response, statusText, xhrObj) {
  //       console.log(response);
  //     }, function(xhrObj, textStatus, err) {
  //       console.log(err);
  //     });
  //   }
  // };

  sliderRes.then(function (response, statusText, xhrObj) {
    var res = response;
    var objNetworks = $('#network-slider');
    var objBooks = $('#book-slider');

    for (var i = 0; i < res.length; i++) {

      if (res[i].slug === 'network-slider') {

        initNetworkSlider( objNetworks, res[i].acf.networks_slider );

      } else if (res[i].slug === 'book-slider') {

        initBookSlider(objBooks, res[i].acf.book_slider);

      }
    }
  }, function(xhrObj, textStatus, err) {
    console.log(err);
  });

initAutoScroll($('#top-shows-slider'), 331, 34, 3000);
initAutoScroll($('#sponsors-slider'), 205, 27, 3000);
initAutoScroll($('#book-slider'), 129, 16, 3000);

})(jQuery);
